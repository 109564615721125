<template>
    <a-layout>
        <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
            <mymenu />
        </a-layout-sider>
        <a-layout>
            <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
                <!-- 内容填充区 -->
                商户资质提交
                <a-select v-model:value="cate" style="width: 120px" :options="f_name" placeholder="{{cate}}" />
                <a-form ref="formRef" name="dynamic_form_item" :model="merchantlist">
                    <a-form-item v-for="(item, index) in merchantlist" :key="index">
                        <a-form-item v-show="item.type == 'input'" :label=item.label :name=item.name>
                            <a-input v-model:value="user_form.company" />
                        </a-form-item>
                        <a-radio-group v-for="i, j in item.options" :key="j" v-show="item.type == 'radio'"
                            v-model:value="user_form.value" name="radioGroup">
                            <a-radio :value=k v-for="k, v in i" :key="v">{{ k }}</a-radio>
                        </a-radio-group>
                    </a-form-item>
                </a-form>
                <a-upload v-model:file-list="filelist" :beforeUpload="beforeUpload" @change="handleChange">
                    <a-button>自证视频上传</a-button>
                </a-upload>
                <a-button @click="submit">提交表单</a-button>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import menu from "./menu.vue";
export default {

    components: {
        mymenu: menu
    },
    data() {
        return {
            key: "value",
            name: '',
            merchantlist: [],
            chardCount: 0,
            cate: '游戏厂商',
            f_name: [{ 'value': "游戏厂商", "lable": '1' }, { 'value': "电脑厂商", "lable": '2' }, { 'value': "汽车厂商", "lable": '3' }],
            user_form: {
                company: "",
                value: "",
                file: "",
            },
            file_obj_name: '',
            file_name: '',
        }
    },
    methods: {
        submit() {
            // console.log();
            // var user_form = {
            // 'company': 0
            // }
            // user_form['about'] = this.lsit1.adver[this.node - 1]['name']
            // user_form = JSON.stringify(user_form)
            // console.log(user_form);
            this.myaxios("/my_form/", "put", { "form": JSON.stringify(this.user_form) }).then((res) => {
                if (res.code == 200) {
                    this.$message.info(res.message)

                } else {
                    this.$message.info(res.message)
                }
            })
        },
        task(filename, shardCount, size) {
            console.log("filename", filename);
            this.myaxios("http://127.0.0.1:8001/scheduler/", "get", { "filename": filename, "count": shardCount, "size": size, "job_id": "1" }).then(data => {
                console.log(data);
            });
        },

        get_list() {
            console.log("name====>", this.name);
            if (this.$route.params.name == '商户资质提交') {
                this.myaxios("/put_from/", "get", { "name": this.cate }).then((res) => {
                    console.log(res);
                    if (res.errcode == 0) {
                        res.data = JSON.parse(res.data)
                        var item = []
                        for (let index = 0; index < res.data.length; index++) {
                            // console.log(res.data[index])
                            item.push(res.data[index])
                            // console.log("真在添加的数据", item);

                        }

                        for (let i = 0; i < item.length; i++) {
                            this.merchantlist.push(JSON.parse(item[i]['value']))

                        }
                        // console.log("表单数据", this.list2);
                        this.$message.info(res.msg)
                    } else {
                        this.$message.info(res.msg)
                    }
                })
            }
        },


        addfile() { },
        //上传方法
        handleChange(file) {
            
            var size = file.file.size
            var filename = file.file.name
            this.user_form.file = file.file.name
            console.log("file.file===>", file.file);
            var shardSize = 20 * 1024 * 1024
            // 总片数
            // 向上取整
            this.chardCount = Math.ceil(size / shardSize)
            console.log('chardCount===>', this.chardCount);
            for (let i = 0; i < this.chardCount; ++i) {
                var start = i * shardSize
                var end = Math.min(size, start + shardSize)
                // 切片
                console.log("start===>", start);
                console.log('end===>', end);
                var shardfile = file.file.slice(start, end)
                console.log(shardfile);
                var formdata = new FormData();
                formdata.append("file", shardfile)
                formdata.append("websize", end - start)
                formdata.append("count", i)
                formdata.append("all_count", this.chardCount)
                formdata.append("filename", filename)
                formdata.append('token', localStorage.getItem('token'))
                const axioscon = this.axios.create({ withCredentials: false })
                axioscon({
                    method: 'post',
                    url: "/ad/upload/",
                    data: formdata,

                }).then((res) => {
                    console.log(res);
                });
            }
            this.myaxios('/ad/upload/', 'put', { "filename": filename }).then((res) => {
                console.log(res);
                this.task(filename, this.chardCount, size)
                this.file_obj_name = filename
            }).catch((err) => {
            });
        },
        beforeUpload() {
            return false;
        },

        get_menrchant() {
            this.myaxiox('/merchant/', 'get', { 'name': this.name }).then((res) => {
                console.log(res);
                if (res.errcode == 0) {
                    this.merchantlist = res.data
                } else {
                    this.$message.info(res.msg)
                }
            }).catch((err) => {

            });
        },
    },
    mounted() {
        window.onbeforeunload = function (e) {
            e = e || window.event;
            console.log(e);
            // 兼容IE8和Firefox 4之前的版本
            if (e) {
                e.returnValue = '关闭提示';
            }
            // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
            return '关闭提示';
        };
    },
    created() {



        if (this.$route.params.name) {
            // this.$router.push({ name: ''})
            // this.$router.push({ path: '/'})

            this.get_list();
        } else {
            this.$router.push({ path: '/' })
            console.log('false');
        }
        console.log(this.$route.params);

    },

}
</script>

<style scoped>
@import url('../assets/style.css');
</style>